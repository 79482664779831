const USER_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 550,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: true,
    singleSelect: true,
    showToggle: false,
    maintainSelected: true,
    sortName: 'username',
    sortOrder: 'desc',
    uniqueId: 'guid'
}

const USER_TABLE_COLUMS = [
    {
        field: 'state',
        checkbox: true
    }, {
        field: 'username',
        title: '아이디',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'realm',
        title: '사용자명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: true
    }, {
        field: 'password',
        title: '비밀번호',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false
    }, {
        field: 'email',
        title: '이메일',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'group_guid',
        title: '그룹',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'created_at',
        title: '생성일자',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'group_name',
        title: '그룹명',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'service_type',
        title: '서비스종류',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: true
    }, {
        field: 'site_guids',
        title: '사이트',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: true
    }, {
        field: 'default_site_guid',
        title: '기준사이트',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        visible: false
    }
]

const SITE_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 360,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: true,
    singleSelect: false,
    showToggle: false,
    maintainSelected: true,
    sortName: 'name',
    sortOrder: 'desc',
    uniqueId: 'guid',
}

const SITE_TABLE_COLUMS = [
    {
        field: 'checked',
        checkbox: true
    }, {
        field: 'name',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'address',
        title: '주소',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        visible: false
    }
]

export {
    USER_TABLE_OPTIOINS,
    USER_TABLE_COLUMS,
    SITE_TABLE_OPTIOINS,
    SITE_TABLE_COLUMS
}