<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Component Settings Dialog"
    color="info"
    size="lg"
  >

    <template #header>
      <h6 class="modal-title text-center">상황판 설정(관리자 : {{user_name}})</h6>
    </template>

    <div class='modal-body'>
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="card">
            <div class="card-header" style="height: 3rem;">
              <h4><b>상황판에 표현될 항목을 선택 하세요.</b></h4>
            </div>

            <div class="card-body">
              <ol start="1">
                <li>왼쪽의 "설정 가능목록"중 하나를 <b>마우스 드래그(클릭상태에서 이동)<i class="fa fa-fw fa-arrows-alt"></i> </b> 하여
                  <br> 오른쪽의 "선택된 항목"에 넣습니다.</li>
                <li>선택된 항목을 <b>마우스 드래그</b>를 통해, 화면에 보여질 순서를 변경 할 수 있습니다.</li>
                <li>선택 및 정렬 완료 후
                  <button type="button" class="btn btn-sm btn-success "> 저장 </button> 버튼 클릭시, 상황판에 즉시 반영 됩니다.
                </li>
                <li>
                  <button type="button" class="btn btn-sm btn-info "> 초기화 </button> 버튼 클릭시, 기본값으로 대체됩니다.
                </li>
              </ol>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class='col-sm-12 col-lg-12'>
          <CButton class="float-right" @click="cancel()" color="light">닫기</CButton>
          <CButton class="float-right mr-1" @click="reset()" color="info">초기화</CButton>
          <CButton class="float-right mr-1" @click="save()" color="success">저장</CButton>
        </div>
      </div>

      <br/>
      <div class="row apps-container">

        <div class="col-sm-offset-0 col-sm-6 col-lg-6">
          <h4><b> <FontAwesomeIcon :icon="['fa', 'list']" aria-hidden="true"/> 설정 가능목록</b></h4>
          <draggable class="apps-container float-left" style="background-color:rgb(233, 222, 222)" tag="ul" v-model="components[0]" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
            <transition-group type="transition" class="list-group" :name="'flip-list'">
              <li class="list-group-item" v-for="element in components[0]" :key="element.order">
                <FontAwesomeIcon :icon=element.icon aria-hidden="true"/>
                | {{element.title}}
              </li>
            </transition-group>
          </draggable>
        </div>

        <div class="col-sm-6 col-lg-6">
            <h4><b><FontAwesomeIcon :icon="['fa', 'list-ol']" aria-hidden="true"/> 선택된 항목</b></h4>
          <draggable class="apps-container float-left" style="background-color:#bee9be" tag="span" v-model="components[1]" v-bind="dragOptions" :move="onMove">
            <transition-group name="no" class="list-group" tag="ul">
              <li class="list-group-item" v-for="element in components[1]" :key="element.order">
                <input type="checkbox" v-model="element.dashboard">&nbsp;
                <FontAwesomeIcon :icon=element.icon aria-hidden="true"/>
                | {{element.title}}
              </li>
            </transition-group>
          </draggable>
        </div>        
      </div>
    </div>

    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>
    <Confirm
        ref="confirmDialog"
        title="확인"
        which="update"
        @hide="hideModal"
    />

  </KModal>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DashboardSetting',
  components: {
    draggable
  },
  data() {
    return {
      showModal: false,
      user_name: '',
      dashboard_guid: '',
      components: [[], []],
      origin_components: [[], []],
      editable: true,
      isDragging: false,
      delayedDragging: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.components[0], null, 2);
    },
    list2String() {
      return JSON.stringify(this.components[1], null, 2);
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },  
  methods: {
    hideModal(event) {
      if (event.data !== 'confirm') {
        return; // cancel
      }

      var components = 
        _.map(this.components[0], el => {
          el.enable = false; return el;
        })
      components = _.concat(components, 
        _.map(this.components[1], el => {
          el.enable = true; return el;
        })
      )
      var where = {
        guid: this.dashboard_guid
      }
      var data = {
        components: components
      }
      this.$store.dispatch('rest/upsertWithWhere', {model: 'dashboards', where: where, data: data})
        .then((res) => {
          console.log("DashboardSetting upsert dashboard ok:", res);
          let msg = {
            name: this.$options.name,
            which: 'save',
            data: 'ok'
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch((err) => {
          console.log('DashboardSetting upsert error: ', err.toString())
          let msg = {
            name: this.$options.name,
            which: 'save',
            data: {err:err.toString()}
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
    },
    save() {
      this.$refs.confirmDialog.show('수정하시겠습니까?', '상황판 저장');      
    },
    reset() {
      this.components = _.cloneDeep(this.origin_components)
    },
    cancel() {
      this.showModal = false
    },
    show(components, user_name, guid) {
      this.components = components;
      this.user_name = user_name
      this.dashboard_guid = guid
      this.origin_components = _.cloneDeep(components);
      // console.log('DashboardSetting::show components:', JSON.stringify(components,null,2))
      this.showModal = true
    },
    log: function(evt) {
      console.log('DashboardSettings logging: ', evt);
    },
    orderList() {
      this.components[0] = this.components[0].sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    }    
  }
}
</script>

<style>
.apps-container {
  /* border: 2px dashed blue; */
  border: 1px solid rgb(152, 152, 160);
  margin: 10px 10px 0 0;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>